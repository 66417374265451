import React from 'react';
import { Link, graphql } from 'gatsby';
import { localizer } from '../util/localizer';
import Layout from '../components/PageLayout';

export default function PageNotFound(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const sitedata = props.data.siteYaml;
  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <div className="message-page white-on-pary">
        <h1>{sitedata.errorpage.header}</h1>
        <h2>{sitedata.errorpage.subheader}</h2>
        <div>
          <Link to={localized('/')} className="std-btn btn-white-on-light">
            {sitedata.homelink}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query site404Q($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      sitename
      locale
      variation
      homelink
      errorpage {
        appError
        header
        subheader
      }
      sections {
        title
        linkto
      }
      navbar {
        theme
      }
      cookie {
        text
        linkText
        accept
        reject
      }
      footer {
        text
        theme
        links {
          label
          to
        }
      }
    }
  }`;
